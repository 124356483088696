import React from "react"
import PropTypes from "prop-types"
import { useSeoData } from "../queries/seoGraphql"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = ({ location }) => {
  const { siteMetadata } = useSeoData()
  return (
    <Layout location={location} title={siteMetadata.siteTitle}>
      <SEO title="404: Not Found" />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <h1 style={{ paddingBottom: "10px" }}>Not Found</h1>
        <p>
          You just hit a route that doesn&#39;t exist...click{" "}
          <a href="/">here</a> to return to the homepage.
        </p>
      </div>
    </Layout>
  )
}

NotFoundPage.propTypes = {
  location: PropTypes.objectOf(PropTypes.string).isRequired,
}

export default NotFoundPage
